<lib-modal-window
  [isVisible]="show"
  (isClose)="close()"
  [isLoading]="isLoading"
>
  <div class="create-subscription-body">
    <ng-template #titleTemplate let-visible="visible">...</ng-template>
    <section *ngIf="isShowTabset">
      <div class="takeprofit-title" *ngIf="isNotSuccessful">
        <lib-takeprofit-title [desktopOnly]="true"></lib-takeprofit-title>
      </div>
      <lib-step
        [number]="tabIndex"
        [type]="type"
        *ngIf="isNotSuccessful"
      ></lib-step>

      <nz-tabset [nzSelectedIndex]="tabIndex">
        <nz-tab *ngIf="type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION">
          <lib-choose-account-size
            [update]="update"
            #step
          ></lib-choose-account-size>
          <br />
        </nz-tab>
        <nz-tab *ngIf="type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION">
          <lib-choose-trading-platform
            [update]="update"
            #step
          ></lib-choose-trading-platform>
        </nz-tab>
        <nz-tab>
          <lib-confirm-subscription
            [accountType]="accountType"
            [type]="type"
            [update]="update"
            [tradingData]="tradingData"
            [accountSize]="accountSize"
            [monthlyPrice]="monthlyPrice"
            [accountId]="subscriptionsId"
            [isConfirmSubscription]="isConfirmSubscription"
            (paymentMethod)="choosePayment($event)"
            (toPayment)="next()"
            (promocodeIdChange)="setPromocode($event)"
            (priceChange)="changePrice($event)"
            (promoAmount)="changePromoAmount($event)"
            #step
          >
          </lib-confirm-subscription>
          <div
            *ngIf="type == TypeOfCreateSubscription.CREATE_SUBSCRIPTION"
            class="px-2"
          >
            <nz-form-item>
              <nz-form-control>
                <div class="checkbox-block">
                  <input
                    nz-input
                    type="checkbox"
                    name="acceptTermsOfService"
                    id="acceptTermsOfService"
                    class="primary-input"
                    [formControl]="acceptTerms"
                  />
                  <label
                    >I accept
                    <a
                      target="_blank"
                      href="https://takeprofittraderhelp.zendesk.com/hc/en-us/categories/15135982702621-Test-Rules"
                      >test rules</a
                    ></label
                  >
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-tab>
        <nz-tab>
          <div [ngSwitch]="paymentMethod">
            <div *ngSwitchCase="PaymentMethod.CREDIT_CARD_NMI">
              <lib-payment-credit-card-nmi
                [update]="updateNmi"
                (next)="nextStep()"
                (loading)="isLoading = $event"
                [subscriptionsId]="subscriptionsId"
                [promocodeId]="promocodeId"
                [price]="price"
                [(subscription)]="subscription"
                [type]="type"
                #step
                [hidden]="PaymentMethod.CREDIT_CARD_NMI !== paymentMethod"
              ></lib-payment-credit-card-nmi>
            </div>
            <div *ngSwitchCase="PaymentMethod.CREDIT_CARD_NUVEI">
              <lib-payment-credit-card-nuvei
                [update]="updateNuvei"
                (next)="nextStep()"
                (loading)="isLoading = $event"
                [subscriptionsId]="subscriptionsId"
                [promocodeId]="promocodeId"
                [basePrice]="price"
                [price]="this.promoAmount == 0 ? price : promoAmount"
                [(subscription)]="subscription"
                [type]="type"
                #step
                [hidden]="PaymentMethod.CREDIT_CARD_NUVEI !== paymentMethod"
              ></lib-payment-credit-card-nuvei>
            </div>
            <div *ngSwitchDefault>
              <lib-payment-credit-card
                #step
                [update]="updateStripe"
                [hidden]="PaymentMethod.CREDIT_CARD !== paymentMethod"
                (next)="nextStep()"
                (loading)="isLoading = $event"
                [subscriptionsId]="subscriptionsId"
                [promocodeId]="promocodeId"
                [price]="price"
                [(subscription)]="subscription"
                [type]="type"
              ></lib-payment-credit-card>
            </div>
          </div>
        </nz-tab>
        <nz-tab>
          <lib-successfully
            *ngIf="tabIndex > 1"
            #step
            [tabIndex]="tabIndex"
            [type]="successfulMessage"
            [resetAmount]="resetAmount"
            [accountType]="accountType"
            (loading)="isLoading = $event"
            (isClose)="updateAfterClose()"
          >
          </lib-successfully>
        </nz-tab>
      </nz-tabset>
      <lib-nav
        (next)="next()"
        (prev)="prev()"
        [showBack]="tabIndex !== 0"
        *ngIf="isNotSuccessful"
      ></lib-nav>
    </section>
  </div>
</lib-modal-window>

<lib-info-go-to-pro
  *ngIf="isGoToPro && !isShowTabset"
  [show]="show"
  [isGoToPro]="isGoToPro"
  (isGoToProChange)="emitGotToPro($event)"
  (goToPayChange)="goToPay($event)"
>
</lib-info-go-to-pro>

<lib-confirm-wallet-transaction
  *ngIf="isShowConfirm"
  [(show)]="isShowConfirm"
  (payWithWallet)="payWallet()"
></lib-confirm-wallet-transaction>

<lib-confirm-credits-transaction
  *ngIf="isShowCreditsConfirm"
  [(show)]="isShowCreditsConfirm"
  (payWithCredits)="payCredits()"
></lib-confirm-credits-transaction>
