import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CreditTypeEnum,
  IUserCredits,
  PaymentMethod,
  paymentMethodList,
} from 'repository';
import { User } from 'user';
import { SubscriptionSize, TypeOfCreateSubscription } from '../enum';
import { PaymentsService } from '../services/payments/payments.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lib-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  @Input()
  public isShowItem = {
    stripe: false,
    nmi: true,
    paypal: true,
    deel: false,
    wallet: true,
    nuvei: false,
  };

  @Input()
  public type = TypeOfCreateSubscription.CREATE_SUBSCRIPTION;

  @Input()
  public accountId = 0;

  @Input()
  public price = 0;

  @Input()
  public promoPrice = 0;

  @Output()
  public readonly paymentChange = new EventEmitter<PaymentMethod>();

  @Output()
  public readonly toPayment = new EventEmitter<void>();

  public readonly PaymentMethod = PaymentMethod;
  public readonly TypeOfCreateSubscription = TypeOfCreateSubscription;

  public readonly creditCardImgList = [
    { src: 'mastercard', alt: 'mastercard' },
    { src: 'visa', alt: 'visa' },
    { src: 'american-express', alt: 'american express' },
  ];
  public readonly creditsIcon = '/assets/logos/your-credits.svg';

  public get isUseCredits(): boolean {
    let isUseCredits: boolean;
    switch (this.type) {
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        const isNew = this._userCredits.filter(
          (item) => item.creditType === CreditTypeEnum.FreeTestReset
        );

        isUseCredits = isNew.length > 0;
        break;

      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        const isExist = this._userCredits.filter(
          (item) => +this.idAccount === +item.creditType
        );

        isUseCredits = isExist.length > 0;
        break;

      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        const isNewAgreement = this._userCredits.filter(
          (item) => item.creditType === CreditTypeEnum.ProActivationFee
        );

        isUseCredits = isNewAgreement.length > 0;
        break;
      default:
        isUseCredits = false;
        break;
    }

    return isUseCredits;
  }

  public get useCredits(): number {
    let useCredits: number;
    switch (this.type) {
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        const isNew = this._userCredits.filter(
          (item) => item.creditType === CreditTypeEnum.FreeTestReset
        );

        useCredits = isNew[0].count;
        break;

      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        const isExist = this._userCredits.filter(
          (item) => +this.idAccount === +item.creditType
        );

        useCredits = isExist[0].count;
        break;

      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        const isNewAgreement = this._userCredits.filter(
          (item) => item.creditType === CreditTypeEnum.ProActivationFee
        );

        useCredits = isNewAgreement[0].count;
        break;
      default:
        useCredits = 0;
        break;
    }

    return useCredits;
  }

  public get payment(): PaymentMethod {
    return this._payment;
  }

  public get currentService(): PaymentMethod | undefined {
    return this._currentService;
  }

  public get showWallet(): boolean {
    if (this.promoPrice) {
      return this.promoPrice <= this.walletBalance;
    }

    return this.price <= this.walletBalance;
  }

  public get walletBalance(): number {
    return this.user.walletAmount;
  }

  public get idAccount(): number {
    return +JSON.parse(localStorage.getItem('accountSize')).id;
  }

  public get accountSize(): number {
    return JSON.parse(localStorage.getItem('accountSize'));
  }

  creditTypeToSubscriptions = [
    SubscriptionSize.$12K,
    SubscriptionSize.$25K,
    SubscriptionSize.$50K,
    SubscriptionSize.$75K,
    SubscriptionSize.$100K,
    SubscriptionSize.$150K,
  ];
  private readonly paymentMethodList = paymentMethodList;

  private _payment: PaymentMethod | null;
  private _currentService?: PaymentMethod;
  private _userCredits: IUserCredits[] = [];

  constructor(
    private readonly user: User,
    private readonly paymentsService: PaymentsService
  ) {}

  public ngOnInit(): void {
    this.getCurrentPaymentMethod();
    this.getUserCredits();
  }

  public choosePayment(payment: PaymentMethod): void {
    if (payment === this.payment) {
      return;
    }
    this._payment = payment;
    this.paymentChange.emit(payment);
  }

  public goToPayment(payment: PaymentMethod): void {
    if (this.showWallet) {
      return;
    }

    this.choosePayment(payment);
    this.toPayment.emit();
  }

  private getUserCredits(): void {
    this.paymentsService
      .getYourCredits()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this._userCredits = value.userCredits;
      });
  }

  private getCurrentPaymentMethod(): void {
    const accountId = this.accountId;

    if (
      accountId == null ||
      this.type !== TypeOfCreateSubscription.CHANGE_PAYMENT
    ) {
      return;
    }

    this.paymentsService
      .getCurrentPaymentMethod(accountId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.setCurrentService(
            this.paymentMethodList[res.currentPaymentService]
          );

          if (res.currentPaymentService === 0) {
            this.setCurrentService(PaymentMethod.CREDIT_CARD_NUVEI);
          }
        },
        () => {
          this.setCurrentService(PaymentMethod.CREDIT_CARD_NUVEI);
        }
      );
  }

  private setCurrentService(payment: PaymentMethod): void {
    this.choosePayment(payment);
    this._currentService = payment;
  }
}
