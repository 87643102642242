import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CreateSubscriptionService } from '../create-subscription.service';
import { ErrorCode, Payment, TypeOfCreateSubscription } from '../enum';
import {
  IGetSessionTokenNuvei,
  INuveiPayment,
  IPaymentData,
} from '../interfaces';

import {
  ErrorFormService,
  IItems,
  NotificationService,
  PaymentMethod,
  PaymentTransactionActionType,
  TransactionInfoAction,
  TransactionStatus,
  TypeMessage,
  WINDOW,
} from 'repository';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  debounceTime,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { User } from 'user';
import { ICountry, IState } from 'additional-info';
import { environment } from '@env/environment';
import { interval, Subject } from 'rxjs';
import { SubscriptionService } from './../../../../subscriptions/src/lib/services/subscription.service';

// tslint:disable-next-line:no-any
declare const SafeCharge: any;

@UntilDestroy()
@Component({
  selector: 'lib-payment-credit-card-nuvei',
  templateUrl: './payment-credit-card-nuvei.component.html',
  styleUrls: ['./payment-credit-card-nuvei.component.scss'],
})
export class PaymentCreditCardNuveiComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() update: boolean;
  @Input() subscriptionsId: number;
  @Input() type: TypeOfCreateSubscription;
  @Input() promocodeId = 0;
  @Input() price = 0;
  @Input() basePrice = 0;

  @Input() set isPaid(value: boolean) {
    if (value === this._isPaid) {
      return;
    }

    this._isPaid = value;
    this.isPaidChange.emit(this._isPaid);
  }

  get isPaid() {
    return this._isPaid;
  }

  @Output() isPaidChange = new EventEmitter<boolean>();

  @Input() subscription: IPaymentData;
  @Output() subscriptionChange = new EventEmitter<IPaymentData>();

  @Output() next = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  showWaitText = false;

  showLoader = true;

  private _isPaid = false;

  private countryList: ICountry;
  private _sessionToken: string;

  // tslint:disable-next-line:no-any
  private _sfc: any;
  private _transactionId: string;
  private _userPaymentOptionId: string;
  private _clientUniqueId: string;
  private readonly stopPolling = new Subject<boolean>();

  constructor(
    private readonly _createSubscriptionService: CreateSubscriptionService,
    private readonly _notification: NotificationService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _user: User,
    private readonly zone: NgZone,
    private readonly _errorFormService: ErrorFormService,
    // tslint:disable-next-line:no-any
    @Inject(DOCUMENT) private readonly document: any,
    // tslint:disable-next-line:no-any
    @Inject(WINDOW) private readonly window: any
  ) {}

  ngOnInit() {
    this.initForm();
    this._getCountryList();
  }

  ngOnChanges() {
    this.getSessionToken();
  }

  initForm(): void {
    this._createSubscriptionService.startNuvei
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (this.window.scard) {
            this.document.getElementById('card-field-placeholder').innerHTML =
              '';
            this.destroyNuvei();
          }
        }),
        debounceTime(1000)
      )
      .subscribe((value) => {
        const block = this.document.getElementById('card-field-placeholder');

        if (block) {
          this._sfc = SafeCharge({
            env: 'prod', // Nuvei API environment - 'int' (integration) or 'prod' (production -
            // )
            merchantId: environment.nuveiMerchantId,
            merchantSiteId: environment.nuveiMerchantSiteId,
          });

          this.window.sfc = this._sfc;

          const ScFields = this.window.sfc.fields({
            fonts: [{ cssUrl: '' }],
          });

          const style = {
            base: {
              color: '#dddddd',
              background: '#333333',
              fontFamily: 'Arial, sans-serif',
              fontSmoothing: 'antialiased',
              width: '100%',
              fontSize: '15px',
              '::placeholder': {
                color: '#dddddd',
                background: '#333333',
              },
              ':-webkit-autofill': {
                color: '#dddddd',
                background: '#333333',
              },
            },
            invalid: {
              fontFamily: 'Arial, sans-serif',
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          };

          const scard = ScFields.create('card', { style });
          scard.attach(block);
          this.window.scard = scard;
          this.showLoader = false;
        } else {
          console.error('Element #card-field-placeholder not found');
        }
      });
  }

  getSessionToken(): void {
    this.zone.run(() => {
      this._clientUniqueId = btoa(String(this._user.id + new Date().getTime()));

      const price =
        this.type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION
          ? 0
          : this.price;
      const isAuth =
        this.type === TypeOfCreateSubscription.CHANGE_PAYMENT ||
        this.type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION;
      const body: IGetSessionTokenNuvei = {
        amount: String(price),
        clientRequestId: btoa(this._user.id + this._user.firstName),
        clientUniqueId: this._clientUniqueId,
        currency: 'USD',
        isAuth,
        userTokenId: String(this._user.id),
      };

      this._createSubscriptionService
        .getSessionToken(body)
        .pipe(untilDestroyed(this))
        .subscribe((value) => {
          this._sessionToken = value.sessionToken;
          if (this.window.scard) {
            this.destroyNuvei();
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroyNuvei();
  }

  submit(): boolean {
    this.loading.emit(true);

    if (
      this.type === TypeOfCreateSubscription.RESET_ACCOUNT ||
      this.type === TypeOfCreateSubscription.RESET_PRO_ACCOUNT ||
      this.type === TypeOfCreateSubscription.AGREEMENT_PAYMENT
    ) {
      let action: PaymentTransactionActionType;
      if (
        this.type === TypeOfCreateSubscription.RESET_ACCOUNT ||
        this.type === TypeOfCreateSubscription.RESET_PRO_ACCOUNT
      ) {
        action = PaymentTransactionActionType.Reset;
      }

      if (this.type === TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        action = PaymentTransactionActionType.CreateAgreement;
      }

      const body: {
        clientUniqueId: string;
        accountId: number;
        promocodeId: number;
        action: number;
        amount: number;
        // tslint:disable-next-line:no-any
      } = {
        clientUniqueId: this._clientUniqueId,
        accountId: this.subscriptionsId,
        promocodeId: this.promocodeId,
        action,
        amount: this.price,
      };

      this._subscriptionService
        .pendingNuveiTransactionCommand(body)
        .pipe(untilDestroyed(this))
        .subscribe();
    }

    this._sfc.createPayment(
      {
        sessionToken: this._sessionToken,
        cardHolderName: this._user.firstName + ' ' + this._user.lastName,
        paymentOption: this.window.scard,
        clientUniqueId: this._clientUniqueId,
        billingAddress: {
          firstName: this._user.firstName,
          lastName: this._user.lastName,
          email: this._user.email,
          country: this.countryList.tradovateName,
        },
        userDetails: {
          firstName: this._user.firstName,
          lastName: this._user.lastName,
          email: this._user.email,
          country: this.countryList.tradovateName,
        },
      },
      (res: INuveiPayment) => {
        // tslint:disable-next-line:no-console
        console.log('nuveiResponse', res);

        if (res.result !== 'APPROVED') {
          this._notification.showBlankNotification(
            'Your payment failed. Please try again or contact support. | ' +
              res?.errorDescription,
            TypeMessage.ERROR
          );
          this.loading.emit(false);

          return;
        }
        this._transactionId = res.transactionId;
        this._userPaymentOptionId = res.userPaymentOptionId;

        switch (this.type) {
          case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
            this.showWaitText = true;
            this._createSubscribe((data) => {
              this._getClientSecret(data);
            });
            break;
          case TypeOfCreateSubscription.RESET_ACCOUNT:
          case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
            this._resetAccount((data) => {
              this._getClientSecret(data);
            });

            break;
          case TypeOfCreateSubscription.CHANGE_PAYMENT:
            this._createSubscriptionService.changePaymentMethod(
              Payment.Nuvei,
              this.subscriptionsId,
              (data) => {
                this._getClientSecret(data);
              },
              null,
              null,
              this._transactionId,
              this._userPaymentOptionId
            );
            break;
          case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
            this.showWaitText = true;
            this._payAgreement((data) => {
              this._getClientSecret(data);
            });

            break;
          case TypeOfCreateSubscription.DEPOSIT:
            /*        this._depositWallet((data) => {
                this._getClientSecret(data);
              });*/

            break;
          default:
            break;
        }
      }
    );

    return false;
  }

  private destroyNuvei(): void {
    this.window.scard?.destroy();
    this.window.scard = null;
    this.showLoader = false;
  }

  private filterNameById(list: ICountry[], id: number): ICountry[] {
    return list.filter((word: ICountry | IState) => word.id === id);
  }

  private _getCountryList(): void {
    this._createSubscriptionService
      .getCountryList()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: IItems<ICountry>) => {
          if (!res.items) {
            return;
          }

          this.countryList = this.filterNameById(
            res.items,
            this._user.countryCode
          )[0];
        },
        (error) => console.error(error)
      );
  }

  private _getClientSecret(data: IPaymentData): void {
    if (!data) {
      return;
    } else if (
      data.errorDoublePayment === ErrorCode.ERROR_CODE_DOUBLE_PAYMENT
    ) {
      this.loading.emit(false);
      // Show error to your customer
      this._notification.showBlankNotification(
        'Error: Payment has already been made, please wait for your account status to be updated',
        TypeMessage.ERROR
      );

      return;
    }

    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        const startTime = Date.now();

        interval(10000)
          .pipe(
            takeWhile(() => Date.now() - startTime < 100000),
            takeUntil(this.stopPolling),
            untilDestroyed(this),
            switchMap(() =>
              this._subscriptionService.getSubscriptionTransactions(
                this.type === TypeOfCreateSubscription.AGREEMENT_PAYMENT
                  ? this.subscriptionsId
                  : data.subscriptionRes
              )
            )
          )
          .subscribe((res) => {
            if (
              res[0]?.status === TransactionStatus.Failed &&
              (res[0]?.info.action === TransactionInfoAction.Create ||
                res[0]?.info.action === TransactionInfoAction.CreateAgreement)
            ) {
              this._notification.showBlankNotification(
                'Your payment failed. Please try again or contact support.',
                TypeMessage.ERROR
              );
              this.loading.emit(false);
              this.showWaitText = false;
              this.stopPolling.next();
              this.showWaitText = false;
              this.initForm();
            } else if (res[0]?.status === TransactionStatus.Succeeded) {
              this.isPaid = true;
              this.destroyNuvei();
              this.loading.emit(false);
              this.showWaitText = false;
              this.stopPolling.next();
              this.next.emit();
            }
          });

        break;
      default:
        this.isPaid = true;
        this.showWaitText = false;
        this.destroyNuvei();
        this.loading.emit(false);
        this.next.emit();
        break;
    }
  }

  private _resetAccount(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.resetAccount(
      PaymentMethod.CREDIT_CARD_NUVEI,
      this.basePrice,
      this.promocodeId,
      this.subscriptionsId,
      (data) => {
        cb(data);
      },
      null,
      null,
      '',
      this._transactionId,
      this._userPaymentOptionId
    );
  }

  private _payAgreement(cb: (data: IPaymentData) => void): void {
    this._createSubscriptionService.payAgreement(
      PaymentMethod.CREDIT_CARD_NUVEI,
      this.promocodeId,
      this.subscriptionsId,
      (data) => {
        cb(data);
      },
      null,
      null,
      '',
      this._transactionId,
      this._userPaymentOptionId
    );
  }

  /*
       private _depositWallet(cb: (data: IPaymentData) => void): void {
         this._createSubscriptionService.depositWallet(
           PaymentMethod.CREDIT_CARD,
           this.price,
           (data) => {
             cb(data);
           }
         );
       }
     */
  private _createSubscribe(cb: (data: IPaymentData | undefined) => void): void {
    this.loading.emit(true);
    if (this.subscription) {
      this.subscriptionChange.emit(this.subscription);
      if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
        this.loading.emit(false);
      }
      this.isPaid = true;
      cb(this.subscription);

      return;
    }

    this._createSubscriptionService.createSubscribe(
      PaymentMethod.CREDIT_CARD_NUVEI,
      this.promocodeId,
      (data) => {
        const subscriptionRes = data?.subscriptionRes;
        if (subscriptionRes) {
          this.subscription = data;
          this.subscriptionChange.emit(this.subscription);
        } else {
          if (this.type !== TypeOfCreateSubscription.AGREEMENT_PAYMENT) {
            this.loading.emit(false);
            this.showWaitText = false;
            this._notification.showBlankNotification(
              'Your payment failed. Please try again or contact support.',
              TypeMessage.ERROR
            );
          }
          this.isPaid = true;
        }
        cb(data);
      },
      null,
      null,
      '',
      this._transactionId,
      this._userPaymentOptionId
    );
  }

  /*
    private _payWithCard(clientSecret: string): void {
      this.loading.emit(true);
      this._stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: this._card,
          },
        })
        // TODO: fix any
        // tslint:disable-next-line:no-any
        .then((result: any) => {
          const error = result.error;
          if (error) {
            this.loading.emit(false);

            // Show error to your customer
            this._notification.showBlankNotification(
              'Error: ' + error.message,
              TypeMessage.ERROR
            );

            return;
          }

          // The payment succeeded!
          this.next.emit();
          this.loading.emit(false);
        })
        .catch(() => {
          this._notification.showBlankNotification(
            'Something went wrong...',
            TypeMessage.ERROR
          );
          this.loading.emit(false);
        });
    }

    private _setNewCard(clientSecret: string): void {
      this._stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: this._card,
          },
        })
        // TODO: fix any
        // tslint:disable-next-line:no-any
        .then((result: any) => {
          const error = result.error;
          if (error) {
            this.loading.emit(false);

            // Show error to your customer
            this._notification.showBlankNotification(
              'Error: ' + error.message,
              TypeMessage.ERROR
            );

            return;
          }

          // The payment succeeded!
          this.next.emit();
        })
        .catch(() => {
          this._notification.showBlankNotification(
            'Something went wrong...',
            TypeMessage.ERROR
          );
          this.loading.emit(false);
        });
    }*/
}
